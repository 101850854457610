.personal-info-form {
  padding: 20px;
  padding-left: 30px;
}
.personal-info-input-feilds {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

@media (max-width: 425px) {
  .personal-info-input-feilds {
    align-items: center;
  }
}
.save-info-button {
  background-color: #759ffa;

  text-align: center;
  border: none;
  height: 40px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
  text-transform: uppercase;
  border-radius: 100px;
  padding: 8px;
}
.save-info-button span {
  padding: 8px;
}

.hide-change-button {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  height: 32px;
  color: #fff !important;
  border: none;
  border-radius: 0px;
  padding: 2px 16px 2px 16px;
  font-weight: 600;
}
