.custom-toggle {
  outline: none !important;
  border: none !important;
}
.custom-toggle-dark {
  background: black !important;
  color: white !important;
}
.custom-toggle-light {
  background: white !important;
  color: black !important;
}
.custom-toggle img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid;
}
.custom-item {
  font-weight: 600 !important;
  font-size: 15px !important;
  text-transform: capitalize;
}
.dropdown-menu[data-bs-popper] {
  right: 15% !important;
  left: auto !important;
  top: 70% !important;
}
.navbar-toggler {
  box-shadow: none !important;
}
@media (max-width: 992px) {
  .dropdown-menu[data-bs-popper] {
    top: 35% !important;
    right: 5% !important;
  }
}
