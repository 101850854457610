.topup-main {
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: start;
}
.hidefilterbutton {
  border: none;
  background-color: transparent;
}
.feildlabel {
  font-size: 10px;
  color: #8e8e99;
}
.clearbutton {
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 0px;
  background-color: trasparent;
  cursor: pointer;
  font-weight: 500;
  margin-top: 24px;
  height: 40px;
}
.tablerow {
  height: 56px;
  padding: 0 10px 15px;
  font-size: 10px;
  text-align: center;
  color: #8e8e99;
}

.topup-input-feilds {
  display: flex;
  align-items: start;
  flex-direction: row;
  flex-wrap: wrap;
}
.topup-buttons {
  display: flex;
  gap: 20px;
}
.filter-button {
  background-color: #759ffa;

  border: none;
  border-radius: 30px;
  text-transform: capitalize;
  font-weight: 500;
  color: white;
  padding: 8px;
  cursor: pointer;
}

.filter-button span {
  padding: 8px;
}
.clear-button {
  background-color: #759ffa;

  border: none;
  border-radius: 30px;
  text-transform: capitalize;
  font-weight: 500;
  color: white;
  padding: 8px;

  cursor: pointer;
}

.clear-button span {
  padding: 8px;
}
.label {
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  display: block;
  letter-spacing: 0.025em;

  margin-bottom: 4px;
}
.label-dark {
  color: white;
}
.label-light {
  color: black;
}
.topup-table {
  overflow: scroll;
  margin-top: 20px;
  padding: 20px;
}

.topup-table::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}
.tablehead {
  width: 25%;
  word-break: break-all;
}
