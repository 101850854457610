.allBattleMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 334px) {
  .allBattleMain {
    justify-content: center;
  }
}
.allbattles-main-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  flex-grow: 1;
}
.allbattles-main-div span {
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
}
.allbattles-main-div p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  opacity: 0.5;
}

.create-battle-btn {
  margin-right: 20px;
  margin-top: 15px;
  padding: 10px;
  border: none;
  border-radius: 30px;
  background: #759ffa;
  color: white;
}
.create-battle-btn span {
  font-size: 18px;
  font-weight: 600;
  padding: 5px;
}
/* .grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
  grid-gap: 20px;
  padding: 0 20px;
}
@media (max-width: 335px) {
  .grid-container {
    padding-left: 0px !important;
  }
} */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  padding: 0 20px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

/* Media query for even smaller screens */
@media (max-width: 583px) {
  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
