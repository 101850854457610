.all-battles-main {
  padding: 10px;
  padding-left: 30px;
}
.all-battles-header {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}
.all-battles-header button {
  border: none;
  border-radius: 30px;
  background-color: #759ffa;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-battle {
  cursor: pointer;
  border: none;
  border-radius: 30px;
  background-color: #759ffa;

  color: white;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
}
.start-battle span {
  padding: 8px;
  font-weight: 600;
}
.all-battles-header span {
  font-weight: 600;
  padding: 8px;
  text-align: center;
}
.all-battles-header h2 {
  letter-spacing: -0.02em;
  font-weight: 600;
  font-size: 24px;
}
.battles-pricing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.battle-modal-card {
  /* height: 360px; */
  border-radius: 12px;
}
.battles-pricing span {
  font-size: 16px;
  font-weight: 500;
}
.battles-mood-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.Toggles-input-feilds {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.Battles-toggles-checkbox {
  display: flex;
  gap: 10px;
}
.Battles-toggles-checkbox label {
  font-size: 16px;
  font-weight: 600;
}

/* ///add a box */
.addBox {
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.addBox-dark {
  background: #171717;
  box-shadow: 0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.addBox-light {
  border: 1px solid #f1f5f9;
  background: #ffffff;
  box-shadow: 0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.plus-icon-battles {
  font-size: 40px;
}
.modal-done-button {
  border: none;
  border-radius: 30px;
  background-color: #759ffa;

  color: white;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
}
.modal-done-button span {
  padding: 5px;
  font-size: 20px;
  font-weight: 500;
}
.add-battle-btn {
  border: none;
  border-radius: 30px;
  background-color: #759ffa;

  color: white;
  padding: 10px;
  width: 100%;
}
/* .add-battle-btn span {
  padding: 10px;
} */
.battle-item-price {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.02em;
}
.battle-item-price-dark {
  color: #ffffff;
}
.battle-item-price-light {
  color: black;
}
.quantity-controls {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.quantity-text {
  font-size: 16px;
  font-weight: 600;
}
.quantity-text-dark {
  color: white;
}
.quantity-text-light {
  color: black;
}
.plus-icon {
  border: none;
}
.minus-icon {
  border: none;
}
.battle-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  flex-wrap: wrap;
}
.battle-footer-description {
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
}
.battle-footer-description-dark span {
  color: #f1f5f9;
}
.battle-footer-description-light span {
  color: black;
}
.battlemodal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.react-toggle--checked .react-toggle-track {
  background-color: #759ffa !important;
}
.textLabelDark {
  color: #ffffff;
}
.textLabelLight {
  color: #171717;
}
.CardText {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  opacity: 0.4;
  text-align: center;
  letter-spacing: -0.02em;
}
.CardTitle {
  font-size: 14px;
  text-align: center;
}
