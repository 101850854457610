.timeline-body {
  background-color: #474e5d;
  font-family: Helvetica, sans-serif;
}
/* The actual timeline (the vertical ruler) */
.timeline-main {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline-main::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #f1f5f9;
  top: 16px;
  bottom: 0;
  margin-left: 15%;
  width: 2px;
}

/* Container around content */
.timeline-container {
  position: relative;
  background-color: inherit;
}

/* The circles on the timeline */
.timeline-container::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: white;
  border: 4px solid #759ffa;
  top: 44px;
  border-radius: 50%;
  margin-left: 15%;
}

/* Place the container to the left */
.timeline-left {
  left: 0;
}

/* Place the container to the right */
.timeline-right {
  left: 4%;
}

/* Add arrows to the left container (pointing right) */
.timeline-left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
}

/* Add arrows to the right container (pointing left) */
.timeline-right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
}

@media (max-width: 425px) {
  .timeline-right::after {
    left: 28px !important;
  }
}
.timeline-right::after {
  left: -16px;
}
/* The actual content */
.timeline-content-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.timeline-content-1 img {
  height: 110px;
  width: 110px;
}
.timeline-content-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  filter: blur(0.5px);
}
.timeline-content-2 img {
  height: 80px;
  width: 80px;
}
.timeline-content-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  filter: blur(1px);
}
.timeline-content-3 img {
  height: 60px;
  width: 60px;
}

.battle-boxes-timeline {
  max-height: 265px;
  overflow: scroll;
}
.battle-boxes-timeline::-webkit-scrollbar {
  width: 0.5em;
  background-color: transparent;
}

.battle-boxes-timeline::-webkit-scrollbar-thumb {
  background-color: transparent;
}
/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline-main::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-container::before {
    left: 60px;
  }

  /* Make sure all circles are at the same spot */
  .timeline-left::after,
  .timeline-right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .timeline-right {
    left: 0%;
  }
}
/* ///new timeline */

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";

  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline-dark:before {
  background: #1e293b;
}
ul.timeline-light:before {
  background: #f1f5f9;
}
/* ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
} */
ul.timeline > li:before {
  content: " ";
  background: #759ffa;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #759ffa;
  left: 25px;
  width: 10px;
  height: 10px;
  z-index: 400;
}
