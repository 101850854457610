.BoxTabsMain-light {
  background: #f1f5f9;
}
.BoxTabsMain-dark {
  background: #34405e;
}
.BoxTabsMain {
  padding: 20px;
  gap: 16px;
  width: 538px;
  height: 74px;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}
.BoxTabsMain li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.BoxTabsMain li button {
  border: none;
  width: 120px;
  height: 58px;
  border-radius: 100px;
  /* color: black;; */
}
.BoxTabsMain li button:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2) !important;
}
.BoxTabsMain-dark li button {
  background: #34405e;
}
.BoxTabsActive-light {
  background: #ffffff !important;
}
.BoxTabsText {
  font-weight: 600;
  font-size: 18px;
}
.BoxTabsText-img {
  mix-blend-mode: luminosity;
}
.BoxTabsActive-dark {
  background: #ff4d57 !important;
  color: white !important;
}
.BoxTabsActive-dark span {
  font-weight: 600;
}
.load-more-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.load-more-div button {
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 15px;
  gap: 10px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
.load-more-div-light button {
  background: #759ffa;
  box-shadow: 0px 12px 16px -8px rgba(255, 77, 87, 0.3);
}
.load-more-div-dark button {
  background: #ff4d57;
  box-shadow: 0px 12px 16px -8px rgba(255, 77, 87, 0.3);
}

@media (max-width: 575px) {
  .BoxTabsMain {
    width: auto;
    gap: 10px;
    justify-content: space-around;
  }
  .BoxTabsMain li button {
    width: auto;
  }
  .BoxTabsMain li span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
    padding: 5px;
  }
}
@media (max-width: 767px) {
  /* .BoxTabsMain {
    width: auto;
    gap: 10px;
    justify-content: space-around;
  }
  .BoxTabsMain li button {
    width: auto;
  }
  .BoxTabsMain li span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
    padding: 13px;
  } */
}
.unBoxFeatured {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: start;
  gap: 10px;
}
