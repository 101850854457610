.featured-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
}
.battle-img {
  width: 36px;
  height: 40px;
  margin-left: 15px;
  margin-right: 16px;
  margin-top: 3px;
}
@media screen and (max-width: 760px) {
  .battle-img {
    width: 28px;
    height: 32px;
  }
}
@media screen and (max-width: 560px) {
  .battle-img {
    width: 22px;
    height: 24px;
  }
}
@media screen and (max-width: 400px) {
  .battle-img {
    width: 20px;
    height: 22px;
  }
}

.feature-text {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
}
@media screen and (max-width: 760px) {
  .feature-text {
    font-size: 30px;
    font-weight: 600;
  }
}
@media screen and (max-width: 560px) {
  .feature-text {
    font-size: 22px;
    font-weight: 500;
  }
}
@media screen and (max-width: 460px) {
  .feature-text {
    font-size: 20px;
    font-weight: 500;
  }
}
.feature-description {
  width: 580px;
  height: 24px;
  margin-left: 66px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-size: 16px;
  margin-top: 5px;
}
@media screen and (max-width: 760px) {
  .feature-description {
    width: 400px;
    height: 20px;
    font-size: 12px;
    margin-top: 4px;
    margin-left: 60px;
  }
}
@media screen and (max-width: 560px) {
  .feature-description {
    width: 250px;
    height: 15px;
    font-size: 10px;
    margin-top: 3px;
    margin-left: 52px;
  }
}
@media screen and (max-width: 560px) {
  .feature-description {
    width: 196px;
    height: 15px;
    font-size: 9px;
    margin-top: 3px;
  }
}
.discription-col {
  /* margin-top: 5px; */
  display: flex;
}
.see-more-div {
  cursor: pointer;
  margin-right: 15px;
}
@media screen and (max-width: 760px) {
  .see-more-div {
    margin-right: 7px;
  }
}
@media screen and (max-width: 560px) {
  .see-more-div {
    margin-right: 5px;
  }
}
@media screen and (max-width: 400px) {
  .see-more-div {
    margin-right: 3px;
  }
}

.see-more {
  width: 71px;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: black;
  margin-right: 3px;
}
@media screen and (max-width: 760px) {
  .see-more {
    font-size: 13px;
    width: 60px;
    height: 20px;
  }
}
@media screen and (max-width: 560px) {
  .see-more {
    font-size: 11px;
    width: 50px;
    height: 15px;
  }
}
@media screen and (max-width: 560px) {
  .see-more {
    font-size: 9px;
    width: 30px;
    height: 13px;
  }
}
.see-more:hover {
  color: black;
}

.see-more-arrow {
  width: 13.75px;
  height: 13.75px;
}
@media screen and (max-width: 760px) {
  .see-more-arrow {
    width: 10px;
    height: 11px;
  }
}
@media screen and (max-width: 560px) {
  .see-more-arrow {
    width: 8px;
    height: 9px;
  }
}
@media screen and (max-width: 400px) {
  .see-more-arrow {
    width: 6px;
    height: 7px;
  }
}
.battles-div {
  display: flex;
  justify-content: space-evenly;
  overflow: scroll;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
