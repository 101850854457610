.loginFieldArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NEWloginElement {
  padding: 10px;
  position: relative;
}
.NEWloginElement input {
  text-align: left;
}

/* .NEWloginElement .styles_formInput__OwkhS input {
  padding-left: 40px !important;
}
.NEWloginElement .styles_formInput__1UNE6 input {
  padding-left: 40px !important;
} */

.login-icon {
  position: absolute;
  top: 53%;
  margin-left: 8px;

  transform: translateY(-50%);
}
.error-icon {
  position: absolute;
  top: 40%;
  margin-left: 8px;

  transform: translateY(-50%);
}
.login-btn {
  background: rgba(227, 6, 19, 0.1);
  border: none;
  height: 3rem;
  border-radius: 25px;
  width: 6rem;
  color: black;
  font-weight: 700;
  font-size: 14px;
}
.signup-text {
  color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
}
/* //login */

.forget-password {
  padding-left: 13%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  letter-spacing: -0.02em;
  color: #0284c7;
}
.forgetpassword {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.forgetpassheading {
  font-size: 24px;
  font-weight: 700;
}
.forgetpassdesc {
  font-size: 10px;
  font-weight: 400;
  margin-top: 8px;
  margin-left: 3px;
}

.forgetpassheading-dark {
  color: white;
}
.forgetpassdesc-dark {
  color: white;
}
