.hoc {
  width: calc(100% - 230px);
  margin-left: auto;
}
.content_box {
  padding-top: 20px;
  padding-right: 28px;
  padding-left: 20px;
  padding-bottom: 30px;
  /* max-height: calc(100vh - 90px); */
}

.sliderbody{
  padding-left: 20px;
  padding-top: 20px;
}

@media screen and (max-width: 992px) {
  .content_box {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .hoc {
    width: calc(100% - 40px);
  }
}
