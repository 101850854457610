.benefits-main-light {
  box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.01);
  background: #f8fafc;
}
.benefits-main-dark {
  background: #0e1012;
  box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.01);
}

.varietybox-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}
.varietybox-div h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
}
.varietybox-div p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.benefits-sections {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 16px;
}
@media (max-width: 425px) {
  .varietybox-div {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
@media (max-width: 450px) {
  .benefits-sections {
    flex-direction: column;
    flex-wrap: wrap;
  }
}
