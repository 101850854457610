.btn-outline {
  background-color: #759ffa;

  text-align: center;
  border: none;
  height: 40px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 100px;
  padding: 8px;
  text-decoration: none;
}
