.main-heading {
  text-transform: uppercase;
  color: unset;
  margin: 0.5rem !important;
}
.dot {
  color: #ff5548 !important;
  margin-right: 0.25rem !important;
  /* vertical-align: text-bottom!important; */
  font-size: 20px;
}
.livedropdiv {
  display: flex;
  position: relative;
  height: 160px;
  width: 170px;
  min-width: 150px;
  border-radius: 0;
  background: #f0f0f2;
  box-shadow: none;
  transition: 0.25s;
  border: 1px solid;
  margin: 0.3rem !important;
  width: auto;
  border: none;
  margin: 10px;
}

.livedropsubdiv {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: var(--box-recent-opening-item-bg-opacity);
  transition: opacity 0.3s;
  border-radius: inherit;
  background: #c5d7e2;
}
.imgbadge {
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
  width: 16px;
  height: 20px;
}
.card-img {
  padding: 10px;
  text-align: center;
  position: relative;
  display: inline-block;
}
.priceandbutton {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 4px 10px;
}

.priceandbutton span {
  border: 1px solid white;
  background-color: white;
  padding: 2px;
  border-radius: 10px;
}

.topIcon {
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;
  padding-bottom: 0px;
}

.hover-image {
  position: absolute;
  top: 0;
  /* margin-left: 20px; */
  right: 65%;
  display: none;
}

@keyframes slideFromRight {
  0% {
    right: 65%;
    top: 10%;
  }
  100% {
    right: 30%;
    top: 10%;
  }
}

.card-img:hover .hover-image {
  display: block;
  animation: slideFromRight 0.5s ease-in-out forwards;
}

.card-img:hover img:not(.hover-image) {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

@media (min-width: 770px) {
  .mainbody-section1 {
    width: 69% !important;
  }
  .sideBar {
    width: 31% !important;
  }
}
@media (min-width: 890px) {
  .mainbody-section1 {
    width: 75% !important;
  }
  .sideBar {
    width: 25% !important;
  }
}
@media (min-width: 1110px) {
  .mainbody-section1 {
    width: 80% !important;
  }
  .sideBar {
    width: 20% !important;
  }
}
@media (min-width: 1200px) {
  .sideBar {
    width: 19% !important;
  }
  .mainbody-section1 {
    width: 81% !important;
  }
}
@media (min-width: 1350px) {
  .sideBar {
    width: 17% !important;
  }
  .mainbody-section1 {
    width: 83% !important;
  }
}
@media (min-width: 1550px) {
  .sideBar {
    width: 15% !important;
  }
  .mainbody-section1 {
    width: 85% !important;
  }
}
@media (min-width: 1750px) {
  .sideBar {
    width: 13% !important;
  }
  .mainbody-section1 {
    width: 87% !important;
  }
}
@media (min-width: 2200px) {
  .sideBar {
    width: 11% !important;
  }
  .mainbody-section1 {
    width: 89% !important;
  }
}
/* @media (min-width: 768px) {
  .sideBar .col-md-4 {
    width: 25% !important;
  }
} */
