.footer-container {
  left: 0px;
  bottom: 0px;
  padding: 20px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer-container-Light {
  background: linear-gradient(180deg, #f8fafc 0%, rgba(248, 250, 252, 0) 100%);
}
.footer-container-dark {
  background: linear-gradient(180deg, #131417 0%, rgba(19, 20, 23, 0) 100%);
}
.footer-part1 {
  padding: 5%;
  display: flex;

  justify-content: space-between;
  /* margin: 10px; */
  flex-wrap: wrap;
}

/* .logo-img-footer {
  width: 214px;
  height: 32px;
} */
.footer-social-div {
  display: flex;
  flex-direction: column;
}
.footer-social-div img {
  margin-bottom: 10px;
}
.footer-social-div p {
  max-width: 500px;
}
.footer-social-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.footer-driftmaster-div {
  margin: 10px;
}
.socialdiv {
  display: flex;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.social-icons-size {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
.social-icons-fb {
  background-color: #1877f2;
}
.social-icons-twitter {
  background-color: #1ca1f1;
}
.social-icons-insta {
  background: linear-gradient(221.9133573153deg, #5646dc, #e0215e, #f8c75f);
}
.social-icons-discord {
  background-color: #7388da;
}
.social-icons-tiktok-light {
  background-color: #759ffa;
}
.social-icons-tiktok-dark {
  background-color: white;
}
.tiktok-icon-dark-mode {
  color: black !important;
}
.social-icons-youtube {
  background-color: red;
}
.social-icons-twitch {
  background-color: #6441a5;
  border-radius: 50%;
}
.social-icons-kick {
  background: rgb(83, 252, 24, 0.9);
}

.icon-setting {
  cursor: pointer;
  height: 17px;
  width: 17px;
  margin-top: 7px;
  color: white;
}
.footer-payment-div {
  text-align: center;
  margin: 10px;
}
.footer-payments {
  display: flex;
  margin: 10px;
  flex-wrap: wrap;
  justify-content: space-around;
}
.footer-trustpilot {
  flex-grow: 1;
  text-align: center;
  margin: 10px;
}
.footer-trustpilot img {
  cursor: pointer;
}
.trustpilot-text {
  opacity: 0.3;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}
.footer-payment-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.color-mastercard {
  mix-blend-mode: luminosity;
}
.visa-icon {
  height: 70px;
  width: 70px;
  color: #413f3f;
}
.footer-part2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  opacity: 0.5;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: center;
}
.footer-part2-link {
  text-decoration: none;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  font-style: normal;
}
.footer-part2-link-light {
  color: #000000;
}
.footer-part2-link-Dark {
  color: #ffffff;
}

.footer-part3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  text-align: center;
}
.footer-part3-text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  opacity: 0.4;
  letter-spacing: -0.04em;
}
.last-part-footer {
  padding: 5px;
  width: 80%;
  margin: 0 auto;
  border-radius: 50% / 100% 100% 0 0;
  background-color: #759ffa;
}
/* //responsiveness */
.footer-part1 {
  justify-content: center;
}
.footer-social-div {
  align-items: start;
}
.footer-payments {
  flex-grow: 1;
}
.footer-payment-div {
  flex-grow: 1;
  display: flex;
  justify-content: start;
  flex-direction: column;
}
.footer-payment-icons {
  justify-content: center;
}
.trustpilot-text {
  text-align: center;
}
.footer-social-div p {
  text-align: start;
}
@media (max-width: 1335px) {
  .footer-part1 {
    justify-content: center;
  }
  .footer-social-div {
    align-items: center;
  }
  .footer-payments {
    flex-grow: 1;
  }
  .footer-payment-div {
    flex-grow: 1;
    display: flex;
    justify-content: start;
    flex-direction: column;
  }
  .footer-payment-icons {
    justify-content: center;
  }
  .trustpilot-text {
    text-align: center;
  }
  .footer-social-div p {
    text-align: center;
  }
}
@media (max-width: 360px) {
  .social-icons-size {
    height: 28px;
    width: 28px;
  }
  .icon-setting {
    height: 15px;
    width: 15px;
    margin-top: 3px;
  }
  .footer-part3 {
    margin: 7px;
  }
  .socialdiv {
    gap: 0.5rem;
  }
  .footer-payment-div {
    display: block;
  }
}
