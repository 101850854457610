.game-main {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.game-heading {
  font-weight: 400;
}
.game-buttons-main {
  display: flex;
  align-items: center;
  gap: 5px;
}
.game-history-list {
  border: none;
  border-radius: 30px;
  padding: 8px;
  background: #759ffa;
  color: white;
  font-size: 14px;
  font-weight: 600;
}
.game-history-list span {
  padding: 6px;
}
