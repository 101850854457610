/* .css-t3ipsp-control {
  width: 352px;
  background: #f1f5f9 !important;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  height: 52px;
  position: relative;
  min-height: 41px;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  padding: 0px 16px !important;
  letter-spacing: 0.025em;
} */
.select-container {
  width: 352px; /* Default width */
}

@media (max-width: 550px) {
  .select-container {
    width: 230px; /* Full width for smaller screens */
  }
}
