.my-account-dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 50px;
}
.my-account-dashboard-light {
  background: #ffffff;
  box-shadow: 0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.my-account-dashboard-dark {
  background: #171717;
  box-shadow: 0px 40px 40px -40px rgba(200, 118, 251, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.progress-status {
  display: flex;
  gap: 5px;
  align-items: center;
}
@media (max-width: 425px) {
  .my-account-dashboard {
    flex-wrap: wrap;
    /* justify-content: center; */
  }
  .progressbar-div span {
    position: initial !important;
  }
  .section2div2 {
    flex-direction: column;
  }
  .progress-status {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}
.section2 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 85%;
}
.section2div2 {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.progressbar-div {
  /* display: flex; */
  flex: 1;
  position: relative;
}
.progressbar-div span {
  position: absolute;
  right: 5px;
  top: 1px;
}
.progress-bar {
  background-color: #759ffa !important;
}
.section1img {
  height: 90px;
  width: 90px;
}
.progress-bar-text {
  position: absolute;
  top: 0;
  right: -7px;
  bottom: 0;
  left: auto;
  padding: 0 10px; /* adjust the padding as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.buyxp-btn {
  border-radius: 30px;
  text-transform: uppercase;
  background: #759ffa !important;
  color: #fff !important;
  border: none;
  font-weight: 500;
  font-size: 13px;
  padding: 7px;
  /* margin-left: 3px; */
}

.level-button {
  border: none;
  background: none;
  font-weight: 700;
}
.level-button-dark {
  color: #fff;
}
.level-button-light {
  color: #171717;
}
