.product-div {
  text-align: start;
  font-size: 2rem;
}
@media (max-width: 425px) {
  .product-div {
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  .product-div {
    font-size: 1.7rem;
  }
}
.fire-img {
  /* width: 36px;
  height: 40px; */
  margin-left: 15px;
  margin-right: 6px;
  margin-top: -10px;
}
.see-more-product {
  cursor: pointer;
}
.unbox-grid-items {
  display: flex;
  justify-content: start;
}
.product-text {
  text-align: center;
  width: 580px;
  height: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
}
@media screen and (max-width: 760px) {
  .product-text {
    /* width: 400px;
    height: 30px; */
    font-size: 34px;
    font-weight: 600;
  }
}
@media screen and (max-width: 560px) {
  .product-text {
    /* width: 250px;
    height: 20px; */
    font-size: 30px;
    font-weight: 500;
  }
}
@media screen and (max-width: 460px) {
  .product-text {
    /* width: 170px;
    height: 18px; */
    font-size: 26px;
    font-weight: 500;
  }
}
.product-description {
  margin-left: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-size: 16px;
  margin-top: 5px;
}
@media screen and (max-width: 760px) {
  .product-description {
    font-size: 12px;
    margin-top: 4px;
    margin-left: 25px;
  }
}
@media screen and (max-width: 560px) {
  .product-description {
    font-size: 10px;
    margin-top: 3px;
    margin-left: 25px;
  }
}
@media screen and (max-width: 425px) {
  .product-description {
    font-size: 9px;
    margin-top: 3px;
  }
}
.discription-col {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.see-more-text-product {
  margin-right: 15px;
}
@media screen and (max-width: 760px) {
  .see-more-text-product {
    margin-right: 7px;
  }
}
@media screen and (max-width: 560px) {
  .see-more-text-product {
    margin-right: 5px;
  }
}
@media screen and (max-width: 400px) {
  .see-more-text-product {
    margin-right: 3px;
  }
}

.see-more-text {
  width: 71px;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  text-decoration: none;
  color: black;
  margin-right: 3px;
}

.see-more-text-arrow {
  width: 13.75px;
  height: 13.75px;
}
@media screen and (max-width: 760px) {
  .see-more-text-arrow {
    width: 10px;
    height: 11px;
  }
}
@media screen and (max-width: 560px) {
  .see-more-text-arrow {
    width: 8px;
    height: 9px;
  }
}
@media screen and (max-width: 400px) {
  .see-more-text-arrow {
    width: 6px;
    height: 7px;
  }
}

.bg-product-light {
  background-image: url("../../assets/images/productBox/bg-product-light.png");
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-position: right;
}

.bg-product-dark {
  background-image: url("../../assets/images/productBox/bg-product-dark.png");
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-position: right;
}
@media (max-width: 347px) {
  .product-description {
    margin-left: 17px;
  }
  .see-more-text {
    font-weight: 400px;
    font-size: 9px;
    margin-right: 0px;
  }
  .see-more-arrow {
    width: 6px;
    height: 6px;
  }
}
.loader-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 575px) {
  .col-12 {
    width: 50% !important;
  }
}
@media (max-width: 425px) {
  .col-12 {
    width: 100% !important;
  }
}
