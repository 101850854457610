.contact-main {
  padding: 20px;
  padding-top: 20px;
}
.contact-form {
  padding: 20px;
}
.contact-main h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  padding: 20px;
}
.contact-p {
  padding: 20px;
}
.contact-main p {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-size: 16px;
}
.contactus-textfeilds {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
