.circlesSides {
  display: flex;
  justify-content: space-between;
  max-height: 150px;
}

.circlesSides img {
  max-width: 150px;
}

.spinnerHeading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerHeading h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 100%;
}

@media (max-width: 1024px) {
  .spinnerHeading h1 {
    font-weight: 600;
    font-size: 30px;
  }
}
@media (max-width: 991px) {
  .spinnerHeading h1 {
    font-weight: 600;
    font-size: 20px;
  }
}
@media (max-width: 443px) {
  .spinnerHeading h1 {
    font-weight: 600;
    font-size: 15px;
  }
}
.headingShade {
  width: 20px;
  height: 10px;
  background: #759ffa;
  opacity: 0.6;
  filter: blur(220px);
}
.spinnerHeadingLight {
  color: black;
}
.spinnerHeadingDark {
  color: white;
}

.carouselClass {
  background: radial-gradient(
    70% 60% at 20% 50%,
    #0c121f 0%,
    rgba(17, 24, 42, 0) 100%
  );
}

.css-1qzevvg {
  height: 30px !important;
  width: 50% !important;
  /* position: absolute !important; */
  /* top: 50% !important;  */
  /* margin: 0 auto !important;   */
}
.catalog-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.spinnerButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #759ffa;
  box-shadow: 0px 20px 16px -16px #4d6089;
  border-radius: 30px;
  width: 187px;
  height: 48px;
  border: none;
  color: white;
}

.spinnerButton span {
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

@media screen and (max-width: 750px) {
  .circlesSides img {
    display: none;
  }
  .circlesSides h1 {
    padding: 20px;
  }
}

#wheelCircle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainCanvas {
  overflow: hidden;
  max-height: 350px;
  border-bottom: 1px solid black;
}
@media (max-width: 425px) {
  .mainCanvas {
    max-height: 220px;
  }
  .spinner-button-div {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 540px) and (min-width: 426px) {
  .mainCanvas {
    max-height: 220px;
  }
}
@media only screen and (max-width: 700px) and (min-width: 541px) {
  .mainCanvas {
    max-height: 264px;
  }
}
@media only screen and (max-width: 912px) and (min-width: 701px) {
  .mainCanvas {
    max-height: 313px;
  }
}
@media only screen and (max-width: 1040px) and (min-width: 913px) {
  .mainCanvas {
    max-height: 343px;
  }
}

.mainCanvas {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: start;
}

/* .canvas {
  width: -webkit-fill-available;
} */
.spinner-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.try-free-btn {
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
  border: none;
}
.try-free-btn-dark {
  background-color: #759ffa;

  color: white;
}

.try-free-btn-light {
  background-color: transparent;
}
.spinnerModalMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0;
  margin: 2rem;
}
.spinnerModalMainDiv p {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  margin-bottom: 0.5rem !important;
  text-align: center;
}
