.sliderButtonsMain {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 10px;
}
.slider-prev-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 48px;
  height: 48px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
}

/* .sliderMain{
  padding-left: 20px;
  padding-right: 20px;
} */

.slider-prev-button-dark {
  background: rgba(255, 255, 255, 0.07);
}
.slider-prev-button-light {
  background: #ffffff;
}
.slider-next-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #759ffa;
  border-radius: 10px;
  cursor: pointer;
}

.slick-dots {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  margin-top: 15px !important;
}
.slick-dots li {
  margin: -20px 0 !important;
}
@media (max-width: 420px) {
  .slidermain .slick-slide {
    width: 185px !important;
  }
}
@media (max-width: 900px) {
  .slidermain .slick-slide {
    width: 200px !important;
  }
}
@media (max-width: 1700px) {
  .slidermain .slick-slide {
    width: 300px !important;
  }
}
@media (max-width: 2500px) {
  .slidermain .slick-slide {
    width: 250px !important;
  }
}
