.battles-main {
  max-width: 250px;
  margin-bottom: 20px;
  min-height: 400px;
  border: 1px solid #f1f5f9;
  border-radius: 12px;
  position: relative;
}
@media (max-width: 768px) {
  .battles-main {
    flex: 1 0 50%;
    /* Adjust the width based on your needs */
  }
}

/* Media query for mobile */
@media (max-width: 425px) {
  .battles-main {
    flex: 1 0 100%;
    /* Adjust the width based on your needs */
  }
}
.battles-main-light {
  background: #ffffff;
  box-shadow: 0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.battles-main-dark {
  background: #171717;
  box-shadow: 0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid black;
}
.battles-head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 12px;
}
.battles-head-buton {
  background: #759ffa;
  border-radius: 30px;
  border: none;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: #ffffff;
  text-transform: uppercase;
}
.battles-head-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 16px;
}
.battle-head-icons-text {
  border-radius: 30px;
  width: 22px;
  height: 16px;
  padding: 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(12px, -10px);
}
.battle-head-icons-text-light {
  background: #ffe3e5;
  color: black;
}
.battle-head-icons-text-dark {
  background: #5c2024;
  color: #ff5c65;
}

.icon_battle-div {
  display: flex;
}
.battles-hr {
  border: 1px solid #f1f5f9;
  width: 80%;
  margin: 0 auto;
}
.battles-footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.battles-footer-unbox {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  opacity: 0.4;
}
.battles-footer-unbox-light {
  color: black;
}
.battles-footer-unbox-dark {
  color: white;
}
.battles-footer-price {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-left: 5px;
}
.battles-footer-price-dark {
  color: white;
}
.battles-footer-price-light {
  color: white;
}

.battles-div::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.dummy-image {
  width: 30px;
  height: 30px;
  background-color: #dddada;
  border-radius: 50px;
}
.eclipse {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #759ffa;
  border-radius: 50% / 100% 100% 0 0;
  height: 40px;
  width: 80%;
  margin: 0 auto;
  transition: height 0.5s ease;
}

.eclipse_crazy {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #759ffa;
  border-radius: 50% / 100% 100% 0 0;
  height: 40px;
  width: 80%;
  margin: 0 auto;
  transition: height 0.5s ease;
}

.watch-battle-text {
  margin-top: 10px;
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.battles-head-img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

@media (max-width: 1024px) {
  .battle-icon-text {
    font-size: 10px;
  }
  .battle-head-icons-text {
    transform: translate(4px, -10px);
  }
  .timeline-content-1 img {
    height: 110px;
    width: 110px;
  }
  .timeline-content-2 img {
    height: 80px;
    width: 80px;
  }
  .timeline-content-3 img {
    height: 60px;
    width: 60px;
  }
}
@media (max-width: 425px) {
  .timeline-content-1 img {
    height: 100px;
    width: 100px;
  }
  .timeline-content-2 img {
    height: 70px;
    width: 70px;
  }
  .timeline-content-3 img {
    height: 50px;
    width: 50px;
  }
}
