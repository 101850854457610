.setting-container {
  padding: 20px;
  padding-left: 30px;
}
.text1 {
  padding: 0 0.5rem;
  max-width: 100%;
  color: #8e8e99;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 10rem;
  height: 60px;
  /* vertical-align: middle; */
}
.textsetting {
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  letter-spacing: -0.09em;
}

.setting-row {
  display: flex;
  align-items: center;
}
.setting-change-button {
  background-color: #759ffa;

  color: white;
  border: none;
  border-radius: 100px;
  text-align: center;
  width: 247px;
  cursor: pointer;
  padding: 2px 16px 2px 16px;
  height: 42px;
  font-weight: 500;
  text-transform: uppercase;
}
