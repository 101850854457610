.livedropMain {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  overflow-x: auto;
  overflow-y: scroll;
  padding-top: 15px;
}
.maincard {
  min-width: 200px;
}
.maincardarea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}
.livedropMain::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.live-box-small {
  flex: 0 0 164px;
  margin-left: 15px;
}
.live-box-dark {
  height: 64px;
  box-shadow: inset 3px 0px 0px #759ffa;
  background: black;
  border: 1px solid #759ffa;
  border-radius: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* margin-bottom: 20px; */
  cursor: pointer;
}
.live-box-light {
  /* max-width: 170px; */
  height: 64px;
  background: rgba(187, 247, 208, 0);
  box-shadow: inset 3px 0px 0px #759ffa;
  background: white;
  border: 1px solid #759ffa;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  gap: 2px;
  align-items: center;
  /* margin-bottom: 20px; */
  cursor: pointer;
}
/* .box-small {
  margin-right: 20px;
} */
.live-card-img img {
  height: 50px;
  width: 50px;
}
.live-text-dark {
  width: 40px;
  height: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 16px;
  color: #dcfce7;
}

.live-text-light {
  width: 40px;
  height: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 16px;
  color: #14532d;
}

.forward-icon {
  display: flex;
  left: 31.25%;
  right: 31.25%;
  top: 15.62%;
  bottom: 15.62%;
}

.bulletList ul {
  list-style: none;
}
.live-drop-dark {
  color: white;
}
.live-drop-light {
  color: #000000;
}

.bulletList ul li::before {
  content: "\2022";
  color: black;
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  margin-left: -1em;
}

.sideBar {
  /* height: calc(100vh - 25px); */
  border-left: 1px solid rgba(0, 0, 0, 0.04);
}
@media (max-width: 769px) {
  .livedropMain {
    height: auto;
  }
}
.sideBar::-webkit-scrollbar {
  display: none;
}
.mainbody-section1 {
  /* height: calc(100vh - 25px); */
  overflow-y: scroll;
}

.mainbody-section1::-webkit-scrollbar {
  display: none;
}
.maincardarea::-webkit-scrollbar {
  display: none;
}

.maincardarea {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.maincard::-webkit-scrollbar {
  display: none;
}

.maincard {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
