.shipping-address-form {
  padding: 20px;
  padding-left: 30px;
}
.shipping-address-textfeilds {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
@media (max-width: 425px) {
  .shipping-address-textfeilds {
    justify-content: center;
  }
}

.shipping-title {
  display: flex;
  padding: 20px;
  /* justify-content: space-between; */
}
.shipping-title button {
  margin-left: 15px;
}
.hide-change-btn {
  color: white;
  border: none;
  border-radius: 100px;
  text-align: center;
  width: 94px;
  cursor: pointer;
  height: 42px;
  font-weight: 500;
}
.hide-btn {
  background-color: #759ffa;
}

.change-btn {
  background-color: #759ffa;
}
