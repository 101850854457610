.roulette-pro-regular-design-top.vertical {
  height: 2px !important;
  opacity: 0.5 !important;
}
.roulette-pro-regular-design-top {
  background: #ff0000 linear-gradient(0deg, #ff0000, #fd0000) !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: calc(100% + 50px) !important;
}
.roulette-pro-wrapper {
  overflow: visible !important;
}

