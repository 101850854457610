.cardimg-unboxmodal {
  padding: 0px 80px 20px 80px;
}
.spinnermodalhead {
  display: flex;
  justify-content: end !important;
  border-bottom: none !important;
  padding: 5px;
}
.spinner-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.spinner-head p {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  margin-bottom: 0.5rem !important;
}
.product-price-dark {
  color: white;
}

.product-price-light {
  color: black;
}
