.navigation-buttons-main {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 85%;
  right: 2%;
}
@media (max-width: 1500px) {
  .navigation-buttons-main {
    top: 80%;
  }
}
@media (max-width: 1100px) {
  .navigation-buttons-main {
    top: 75%;
  }
}
@media (max-width: 910px) {
  .navigation-buttons-main {
    top: 70%;
  }
  .productslider-prev-button {
    width: 40px !important;
    height: 40px !important;
  }
  .productslider-next-button {
    width: 40px !important;
    height: 40px !important;
  }
}
@media (max-width: 768px) {
  .navigation-buttons-main {
    top: 75%;
  }
  .productslider-prev-button {
    width: 36px !important;
    height: 36px !important;
  }
  .productslider-next-button {
    width: 36px !important;
    height: 36px !important;
  }
}
@media (max-width: 545px) {
  .navigation-buttons-main {
    top: 75%;
    right: 4%;
  }
  .productslider-prev-button {
    width: 33px !important;
    height: 33px !important;
  }
  .productslider-next-button {
    width: 33px !important;
    height: 33px !important;
  }
  .productslider-next-button img {
    height: 14px !important;
  }
  .productslider-prev-button img {
    height: 14px !important;
  }
}
@media (max-width: 460px) {
  .navigation-buttons-main {
    top: 70%;
    right: 4%;
  }
  .productslider-prev-button {
    width: 30px !important;
    height: 30px !important;
  }
  .productslider-next-button {
    width: 30px !important;
    height: 30px !important;
  }
  .productslider-next-button img {
    height: 12px !important;
  }
  .productslider-prev-button img {
    height: 12px !important;
  }
}
@media (max-width: 375px) {
  .navigation-buttons-main {
    top: 68%;
    right: 4%;
  }
  .productslider-prev-button {
    width: 30px !important;
    height: 30px !important;
  }
  .productslider-next-button {
    width: 30px !important;
    height: 30px !important;
  }
  .productslider-next-button img {
    height: 12px !important;
  }
  .productslider-prev-button img {
    height: 12px !important;
  }
}
@media (max-width: 320px) {
  .navigation-buttons-main {
    top: 65%;
    right: 4%;
  }
  .productslider-prev-button {
    width: 25px !important;
    height: 25px !important;
  }
  .productslider-next-button {
    width: 25px !important;
    height: 25px !important;
  }
  .productslider-next-button img {
    height: 10px !important;
  }
  .productslider-prev-button img {
    height: 10px !important;
  }
}
.productslider-prev-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 48px;
  height: 48px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.4;
}
.productslider-prev-button:hover {
  background-color: white;
  opacity: 0.9;
}
.slider-prev-button-dark {
  background: rgba(255, 255, 255, 0.07);
}
.slider-prev-button-light {
  background: #ffffff;
}
.productslider-next-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #759ffa;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.2;
}
.productslider-next-button:hover {
  opacity: 0.9;
}
.slick-slide {
  height: 320px;
}
.text-overlay {
  position: absolute;
  top: 30%;
  left: 20%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: white;
  padding: 10px;
  font-size: 26px;
}

.desciption-overlay {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: white;
}
.slide-content {
  display: inline-block;
}
.slide-container {
  position: relative;
  cursor: pointer;
}
.slide-container img {
  width: 100%;
}
@media (max-width: 1100px) {
  .text-overlay {
    left: 25%;
  }
  .desciption-overlay {
    left: 12%;
  }
}
@media (max-width: 768px) {
  .text-overlay {
    left: 28%;
  }
  .desciption-overlay {
    left: 13%;
  }
}
@media (max-width: 688px) {
  .text-overlay {
    left: 34%;
  }
  .desciption-overlay {
    left: 17%;
  }
}
@media (max-width: 570px) {
  .text-overlay {
    left: 36%;
  }
  .desciption-overlay {
    left: 18%;
  }
}
@media (max-width: 500px) {
  .text-overlay {
    left: 36%;
  }
  .desciption-overlay {
    left: 15%;
  }
}
@media (max-width: 425px) {
  .text-overlay {
    left: 36%;
  }
  .desciption-overlay {
    left: 20%;
  }
}
@media (max-width: 385px) {
  .text-overlay {
    left: 34%;
  }
  .desciption-overlay {
    left: 20%;
  }
}
@media (max-width: 632px) {
  .text-overlay {
    font-size: 24px;
  }
}
@media (max-width: 529px) {
  .text-overlay {
    font-size: 22px;
  }
  .desciption-overlay {
    font-size: 12px;
  }
}
@media (max-width: 495px) {
  .text-overlay {
    font-size: 18px;
  }
  .desciption-overlay {
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .text-overlay {
    font-size: 16px;
  }
  .desciption-overlay {
    font-size: 12px;
  }
}
@media (max-width: 385px) {
  .text-overlay {
    font-size: 13px;
  }
  .desciption-overlay {
    font-size: 10px;
  }
}
