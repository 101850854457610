.heading1 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.6875rem;
  padding-bottom: 1.5rem;
}

.cart-main {
  display: flex;
  /* flex-direction: column; */
  padding: 20px;
  justify-content: space-between;
  align-items: start;
}
.multiselectButton {
  text-align: center;
  text-transform: uppercase;
  border: none;
  background-color: #759ffa;

  cursor: pointer;
  font-weight: 500;
  color: white;
  box-shadow: 0px 12px 8px -12px #4d6089;
  border-radius: 30px;
  height: 35px;
}
.multiselectButton span {
  padding: 10px;
}
.search-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cart-hr {
  flex-grow: 1;
  border: none;
  margin: 0 20px;
}
.cart-hr-light {
  border-top: 1px solid black;
}
.cart-hr-dark {
  border-top: 1px solid white;
}
.cart-description {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.loadmore-btn {
  text-align: center;
  text-align: uppercase;
  border: none;
  border-radius: 30px;
  background-color: #759ffa;

  cursor: pointer;
  font-weight: 500;
  color: white;
}
.loadmore-btn {
  padding: 8px;
}
.Loadmore-div {
  display: flex;
  justify-content: end;
}

.cart-info {
  border-radius: 10px;
  padding: 20px;
  box-shadow: 1px #759ffa;
  margin: 25px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cart-info-dark {
  background: #171717 !important;
  box-shadow: 0px 40px 40px -40px rgba(255, 92, 101, 0.4),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}

.cart-info-light {
  box-shadow: 0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.empty-cart {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  color: gray;
}
