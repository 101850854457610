.overlay-section1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  /* overflow-y: scroll; */
}
.overlay-section1 img {
  height: 150px;
  width: 180px;
}
.overlay-heading {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}
.overlay-section1::-webkit-scrollbar {
  background: transparent;
}
.horizontal-row {
  margin: 0 auto;
  width: 80%;
  margin-top: 20px;
}
.item-desciption {
  font-size: 14px;
  font-weight: 400;
}
.overlay-desciption {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.cross-sign {
  display: flex;
  justify-content: end;
  padding: 10px;
  cursor: pointer;
}
.cross-sign-dark {
  color: white;
}
.cross-sign-light {
  color: black;
}
.drawer {
  width: 350px !important ;
  overflow: scroll;
}
.drawer::-webkit-scrollbar {
  display: none;
}
.drawer-light {
  background: #ffffff;
  border: 1px solid #f1f5f9;
  box-shadow: 0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.drawer-dark {
  background: #192036 !important;
  box-shadow: 0px 40px 40px -40px rgba(255, 92, 101, 0.4),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.price-range-div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 20px;
}
