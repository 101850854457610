.depositBtn {
  border: none;
  border-radius: 30px;
  background-color: #759ffa;

  color: white;
  padding: 0.725rem;
}
.Wallet-deposit-Main {
  display: flex;
  align-items: center;
  padding: 15px;
}

.withdrawlBtn {
  border: none;
  border-radius: 30px;
  background-color: #759ffa;

  color: white;
  padding: 0.725rem;
}

.walletButtons {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.amount-div h6 {
  font-size: 12px;
}
.amount-div-dark {
  color: white;
}
.amount-div-light {
  color: black;
}
.WalletTabsMain {
  padding: 20px;
  gap: 16px;
  width: 300px;
  height: 74px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}
@media (max-width: 768px) {
  .WalletTabsMain {
    padding: 10px;
    gap: 6px;
    width: 223px;
    height: 70px;
  }
  .WalletTabsMain li button {
    border: none;
    width: 94px !important;
    height: 50px !important ;
    border-radius: 100px;
    color: black;
  }
}

.WalletTabsMain li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.WalletTabsMain li button {
  border: none;
  width: 120px;
  height: 58px;
  border-radius: 100px;
  color: black;
}
.WalletTabsMain-light {
  background: #f1f5f9;
}
.WalletTabsMain-dark {
  background: #34405e;
}
.WalletTabsActive-light {
  background: #ffffff;
}
.WalletTabsActive-dark {
  background: #759ffa;
  color: white;
}
.available-cards-div {
  display: flex;
  align-items: center;
}
.payment-card-img {
  height: 40px;
  width: 40px;
}

.cards-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  height: 80px;
  width: 80px;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  gap: 5px;
}
.cards-div-dark span {
  color: white;
}
.cards-div-light span {
  color: black;
}
.deposit-heading-dark {
  color: white;
  text-transform: uppercase;
}
.deposit-heading-light {
  color: black;
  text-transform: uppercase;
  text-align: center;
}

.hr-wallet {
  border: 1px solid #f1f5f9;
}
.hr-withdraw {
  /* border-right: 1px solid #f1f5f9; */
  border-right: 1px solid rgba(241, 245, 249, 0.5);
}
.extra-cards-main {
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */
}
.currency-input {
  background: #f1f5f9;
  border: 1px solid #e2e8f0;
  height: 52px;
  border-radius: 10px;
  text-align: center;
}
.plus-minus-btn {
  font-size: 18px;
  border: none;
  height: 35px;
  width: 35px;
  margin-left: 5px;
}
.additional-values {
  border: none;
  margin: 5px;
  font-size: 15px;
  font-weight: 500;
  background-color: #759ffa;

  border-radius: 30px;
  color: white;
  padding: 8px;
}
.deposit-btn {
  border: none;
  font-size: 15px;
  font-weight: 500;
  background-color: #759ffa;

  border-radius: 30px;
  color: white;
  padding: 8px;
  width: 70%;
}

.close-btn {
  border: none;
  margin: 5px;
  font-size: 15px;
  font-weight: 500;
  background-color: #759ffa;

  border-radius: 30px;
  color: white;
  padding: 8px;
}
.apply-btn-wallet {
  background-color: #759ffa;

  border: none;
  border-radius: 30px;
  text-transform: capitalize;
  font-weight: 500;
  color: white;
  padding: 8px;
  margin-top: 5px;
  cursor: pointer;
}
.apply-btn-wallet span {
  padding: 8px;
}
.btc-input {
  background: #f1f5f9;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  height: 52px;
  outline: none;
}
.btc-conversion {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

@media (max-width: 991px) {
  .btc-conversion {
    flex-wrap: wrap;
  }
  .btc-input {
    width: 100%;
  }
}
.walletAddress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.currency-img {
  height: 30px;
  width: 30px;
}
.wallet-modal-head {
  align-items: unset !important;
}
