.modal-content-light .modal-content {
  background-color: #ffffff !important;
}
.modal-content-dark .modal-content {
  background-color: black !important;
  border: 1px solid;
}

.signup-head {
  display: flex;
  align-items: start;
  flex-grow: 1;
  margin-left: 11%;
}
.signup-head p {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;

  margin-bottom: 0.5rem !important;
}
.signup-head-dark p {
  color: #ffffff;
}
.signup-head-light p {
  color: #000000;
}

.signup-head span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
  opacity: 0.5;
}
.signup-head-dark span {
  color: #ffffff;
}
.signup-head-light span {
  color: #000000;
}
.modal-header {
  align-items: unset !important;
  border-bottom: none !important;
  display: flex !important;
  justify-content: center !important;
}
.modal-footer {
  border-top: none !important;
}
.signup-modal-body {
  display: flex;
}
.loginFieldArea {
  flex-grow: 1;
}
.modal-body-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 10px;
  padding: 20px;
}
.modal-checkbox-div {
  display: flex;
  justify-content: center;

  gap: 4px;
}
.modal-checkbox-div input {
  border-radius: 3px;
  width: 18px;
  height: 18px;
}

.modal-checkbox-div span {
  font-size: small;
  width: 80%;
  line-height: 16px;
  letter-spacing: -0.02em;
  opacity: 0.6;
}
.modal-checkbox-div-dark span {
  color: white;
}
.modal-checkbox-div-light span {
  color: black;
}
.modal-checkbox-div-light input[type="checkbox"] {
  background-color: #ffffff;
}
.modal-checkbox-div-dark input[type="checkbox"] {
  background-color: black !important;
}

.modal-signup-hr {
  opacity: 0.08 !important;
  width: 80% !important;
  margin: 10px auto !important;
}
.modal-signup-social-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.modal-social-main {
  border-radius: 10px;
  height: 48px;
  width: 75%;
  margin-bottom: 10px;
}
.modal-social-main-stream {
  background: #4e4e4e;
}
.modal-social-main-google {
  background: #5383ec;
}
.modal-social-main-facebook {
  background: #316de0;
}
.modal-social-stream {
  display: flex;
  padding: 12px 20px;
  gap: 16px;
}
.modal-social-stream span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.modal-footer-butttons {
  gap: 10px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}
.signup-btn {
  background: #759ffa;
  border-radius: 100px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: none;
}
.signup-btn span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: white;
}
.login-btn {
  width: 25%;
  border-radius: 100px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}
.login-btn-dark {
  background: rgba(255, 77, 87, 0.1) !important;
  color: #ff4d57 !important;
}
.login-btn-light {
  background: rgba(227, 6, 19, 0.1);
  color: black;
}
.close-modal {
  cursor: pointer;
}
.privacylink {
  cursor: pointer;
  text-decoration: none;
}
