.topup-buttons-div {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.modal-body-topup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.modalBodyTopup {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.topup-button {
  border: none;
  border-radius: 30px;
  background-color: #759ffa;

  color: white;
  padding: 0.9375rem;
}
.topup-button span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.sellitems-button {
  background: #759ffa;
  border: none;
  border-radius: 30px;
  color: white;
  padding: 8px;
  padding: 0.9375rem;
}
.sellitems-button span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.LowBalanceMsg {
  text-align: center;
  margin-top: 10px;
}
.low-balance-dark {
  color: white;
}
.closemodal-button {
  background: #759ffa;
  border: none;
  border-radius: 30px;
  color: white;
  padding: 8px;
  padding: 10px;
}
